import React from 'react';
import styled, { css } from 'styled-components';
import { Img } from 'react-image';
import Logo from '~/shared/components/Logo';
import Skeleton from '~/shared/components/Skeleton';
import Rect from '~/shared/components/Rect';
import Link from '~/shared/components/Link';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS } from '~/shared/utils/styled';
import { getProjectImageUrl } from '~/getters';
import { useCurrentChainId } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCurrentChainSymbolicName } from '~/utils/chains';
import Summary from './Summary';
import { DataUnionBadge, StreamStatsBadge } from './Badge';
const Image = styled(Img).withConfig({ displayName: "Image", componentId: "sc-1ne1cyd" }) `
    img& {
        display: block;
        object-fit: cover;
    }
`;
const Placeholder = styled.div.withConfig({ displayName: "Placeholder", componentId: "sc-87kf1o" }) `
    background-image: linear-gradient(135deg, #0045ff 0%, #7200ee 100%);

    ${Logo} {
        height: auto;
        left: 50%;
        max-width: 32%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
    }
`;
const UnstyledThumbnail = ({ src, skeletonize, alt, ...props }) => !!src &&
    (skeletonize ? (React.createElement(Image, { ...props, as: Skeleton, block: true })) : (React.createElement(Image, { ...props, alt: alt || undefined, src: src, loader: React.createElement(Image, { ...props, as: Skeleton, block: true }), unloader: React.createElement(Image, { ...props, as: Placeholder },
            React.createElement(Logo, { color: "black", opacity: "0.15" })) })));
const TileThumbnail = styled(UnstyledThumbnail).withConfig({ displayName: "TileThumbnail", componentId: "sc-1mq1rii" }) `
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;
const Tile = styled.div.withConfig({ displayName: "Tile", componentId: "sc-1gj0bx5" }) `
    position: relative;

    ${({ suppressHover }) => !suppressHover &&
    css `
            ${TileThumbnail} {
                filter: brightness(100%);
                transition: 240ms ease-out filter;
            }

            :hover ${TileThumbnail} {
                filter: brightness(70%);
                transition-duration: 40ms;
            }
        `}
`;
const EditButton = styled(Link).withConfig({ displayName: "EditButton", componentId: "sc-1qdvtvh" }) `
    border: none;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    svg {
        color: ${COLORS.primary};
    }
`;
const UnstyledTileImageContainer = ({ children, height, autoSize: autoSizeProp, ...props }) => {
    const autoSize = autoSizeProp === true || height != null;
    return (React.createElement("div", { ...props },
        children,
        !!autoSize && React.createElement(Rect, { height: height })));
};
const TileImageContainer = styled(UnstyledTileImageContainer).withConfig({ displayName: "TileImageContainer", componentId: "sc-1a3zut4" }) `
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    & & {
        border-radius: 0;
        overflow: visible;
    }

    &.no-border-radius {
        border-radius: 0;
    }

    &:hover {
        ${EditButton} {
            opacity: 1;
        }
    }
`;
const ImageTile = ({ alt, height, showDataUnionBadge, src, noBorderRadius, ...props }) => (React.createElement(Tile, { ...props, suppressHover: true },
    React.createElement(TileImageContainer, { autoSize: true, height: height, className: noBorderRadius ? 'no-border-radius' : '' },
        React.createElement(TileThumbnail, { alt: alt || '', src: src || '' }),
        !!showDataUnionBadge && React.createElement(DataUnionBadge, { top: true, left: true }))));
function MarketplaceProductTile({ product, showDataUnionBadge, showEditButton, ...props }) {
    const chainId = useCurrentChainId();
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(Tile, { ...props },
        React.createElement(TileImageContainer, null,
            React.createElement(Link, { to: R.projectOverview(product.id, routeOptions(chainName)) },
                React.createElement(TileImageContainer, { autoSize: true },
                    React.createElement(TileThumbnail, { src: getProjectImageUrl(chainId, {
                            ...product.metadata,
                            imageIpfsCid: product.metadata.imageIpfsCid || undefined,
                        }) || '' }))),
            React.createElement(StreamStatsBadge, { top: true, left: true, streamIds: product.streams }),
            !!showDataUnionBadge && (React.createElement(DataUnionBadge, { top: true, right: true, linkTo: R.projectOverview(product.id, routeOptions(chainName, undefined, 'stats')) })),
            showEditButton && (React.createElement(EditButton, { to: R.projectEdit(product.id, routeOptions(chainName)) },
                React.createElement(SvgIcon, { name: 'pencilFull' })))),
        React.createElement(Link, { to: R.projectOverview(product.id, routeOptions(chainName)) },
            React.createElement(Summary, { name: (product.metadata && product.metadata.name) || 'Untitled project', description: (product.metadata && product.metadata.creator) || '' }))));
}
export { ImageTile, MarketplaceProductTile };
