import { getIndexerClient } from '~/getters/getGraphClient';
import { GetStreamsDocument, } from '../generated/gql/indexer';
export const defaultStreamStats = {
    latency: undefined,
    messagesPerSecond: undefined,
    peerCount: undefined,
};
export const getStreamStats = async (streamId) => {
    const client = getIndexerClient(137);
    if (!client) {
        return defaultStreamStats;
    }
    const { data: { streams }, } = await client.query({
        query: GetStreamsDocument,
        variables: {
            streamIds: [streamId],
            first: 1,
        },
    });
    const [stream = undefined] = streams.items;
    if (!stream) {
        return null;
    }
    const { messagesPerSecond, peerCount } = stream;
    return {
        latency: undefined,
        messagesPerSecond,
        peerCount,
    };
};
