import React, { useMemo } from 'react';
import { StreamPermission } from '@streamr/sdk';
import styled from 'styled-components';
import { address0 } from '~/consts';
import { Bits } from '~/parsers/StreamParser';
import { MEDIUM } from '~/shared/utils/styled';
import { StreamDraft } from '~/stores/streamDraft';
var StreamType;
(function (StreamType) {
    StreamType["PublicSubscribe"] = "publicSubscribe";
    StreamType["Private"] = "private";
    StreamType["PublicPubSub"] = "publicPubSub";
})(StreamType || (StreamType = {}));
export function StreamTypeSelector({ disabled }) {
    const update = StreamDraft.useUpdateEntity();
    const bits = StreamDraft.useEntity({ hot: true })?.permissions[address0] ?? null;
    const streamType = useMemo(() => {
        if (!bits) {
            return StreamType.Private;
        }
        switch (bits) {
            case Bits[StreamPermission.SUBSCRIBE]:
                return StreamType.PublicSubscribe;
            case Bits[StreamPermission.SUBSCRIBE] | Bits[StreamPermission.PUBLISH]:
                return StreamType.PublicPubSub;
            default:
                return StreamType.Private;
        }
    }, [bits]);
    return (React.createElement(Container, null,
        React.createElement(Item, null,
            React.createElement(RadioContainer, { htmlFor: "publicSubscribe" },
                React.createElement(Radio, { id: "publicSubscribe", type: "radio", name: "streamType", checked: streamType === StreamType.PublicSubscribe, onChange: () => {
                        update((entity) => {
                            entity.permissions[address0] =
                                Bits[StreamPermission.SUBSCRIBE];
                        });
                    }, disabled: disabled }),
                React.createElement("div", null,
                    React.createElement(Title, null, "Public subscribe"),
                    React.createElement(Description, null, "Anyone can read/subscribe to the stream")))),
        React.createElement(Item, null,
            React.createElement(RadioContainer, { htmlFor: "private" },
                React.createElement(Radio, { id: "private", type: "radio", name: "streamType", checked: streamType === StreamType.Private, onChange: () => {
                        update((hot, cold) => {
                            if (cold.permissions[address0] == null) {
                                cold.permissions[address0] = 0;
                            }
                            hot.permissions[address0] = 0;
                        });
                    }, disabled: disabled }),
                React.createElement("div", null,
                    React.createElement(Title, null, "Private subscribe"),
                    React.createElement(Description, null, "Only Ethereum accounts listed below can read/subscribe to the stream.")))),
        React.createElement(Item, null,
            React.createElement(RadioContainer, { htmlFor: "publicPubSub" },
                React.createElement(Radio, { id: "publicPubSub", type: "radio", name: "streamType", checked: streamType === StreamType.PublicPubSub, onChange: () => {
                        update((entity) => {
                            entity.permissions[address0] =
                                Bits[StreamPermission.SUBSCRIBE] |
                                    Bits[StreamPermission.PUBLISH];
                        });
                    }, disabled: disabled }),
                React.createElement("div", null,
                    React.createElement(Title, null, "Public publish & subscribe"),
                    React.createElement(Description, null, "Anyone can write/publish to the stream"))))));
}
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1n6i7ua" }) `
    background: #f1f1f1;
    border-radius: 4px;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1em;
    padding: 1.5em;
`;
const Item = styled.div.withConfig({ displayName: "Item", componentId: "sc-e8dhrp" }) `
    background: #ffffff;
    border-radius: 4px;
    height: 100px;
    display: flex;
    align-items: center;
`;
const Radio = styled.input.withConfig({ displayName: "Radio", componentId: "sc-1dxk15b" }) `
    width: 16px;
    justify-self: center;
    align-self: center;
`;
const RadioContainer = styled.label.withConfig({ displayName: "RadioContainer", componentId: "sc-nmbwx0" }) `
    width: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: 48px auto 48px;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1ux92rx" }) `
    font-weight: ${MEDIUM};
    font-size: 16px;
    line-height: 20px;
`;
const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-1lmeybv" }) `
    font-size: 14px;
    line-height: 24px;
`;
