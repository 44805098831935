import React from 'react';
import styled, { css } from 'styled-components';
import Link from '~/shared/components/Link';
import { useMultipleStreamStatsQuery } from '~/hooks/useStreamStats';
const SingleBadge = styled.div.withConfig({ displayName: "SingleBadge", componentId: "sc-1uo2220" }) `
    display: flex;
    align-items: center;
    font-size: 10px;
    height: 15px;
    line-height: 12px;
    padding: 0 4px;
    background-color: #0324ff;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: white !important;

    a,
    a:link,
    a:active,
    a:focus,
    a:hover,
    a:visited {
        color: white !important;
    }

    > * + * {
        margin-left: 8px;
    }
`;
const BadgeContainer = styled.div.withConfig({ displayName: "BadgeContainer", componentId: "sc-1cauijy" }) `
    align-items: center;
    color: white !important;
    display: flex;
    cursor: default;
    pointer-events: none;
    user-select: none;
    margin: 0.5em;

    a& {
        cursor: pointer;
        pointer-events: auto;
        text-decoration: none !important;
    }

    ${({ top, left, right, bottom }) => !!(top || left || right || bottom) &&
    css `
            position: absolute;
        `}

    ${({ bottom, top }) => !!bottom &&
    !top &&
    css `
            bottom: 0;
        `}

    ${({ left }) => !!left &&
    css `
            left: 0;
        `}

    ${({ right, left }) => !!right &&
    !left &&
    css `
            right: 0;
        `}

    ${({ top }) => !!top &&
    css `
            top: 0;
        `}

    > ${SingleBadge} {
        margin-left: 1px;
    }

    ${SingleBadge}:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    ${SingleBadge}:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    ${SingleBadge}:only-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;
const DataUnionBadge = ({ memberCount, linkTo, linkHref, ...props }) => (React.createElement(BadgeContainer, { ...props },
    React.createElement(SingleBadge, null, "Data Union"),
    memberCount != null && (React.createElement(SingleBadge, null,
        React.createElement(BadgeLink, { to: linkTo, href: linkHref }, memberCount),
        "\u00A0 members"))));
const BadgeLink = ({ ...props }) => React.createElement(Link, { ...props });
const StatsBadge = styled.div.withConfig({ displayName: "StatsBadge", componentId: "sc-413ty4" }) `
    display: flex;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;
    background: rgba(245, 245, 247, 0.6);
    backdrop-filter: blur(13.3871px);
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #323232;

    a,
    a:link,
    a:active,
    a:focus,
    a:hover,
    a:visited {
        color: white !important;
    }

    > * + * {
        margin-left: 8px;
    }
`;
const StreamStatsBadge = ({ streamIds, ...props }) => {
    const { data: stats, isLoading, error } = useMultipleStreamStatsQuery(streamIds);
    if (error || isLoading) {
        return null;
    }
    const messagesPerSecond = stats?.messagesPerSecond;
    const formattedMsgRate = typeof messagesPerSecond === 'number' ? messagesPerSecond.toFixed(1) : 'N/A';
    return (React.createElement(BadgeContainer, { ...props },
        React.createElement(StatsBadge, null,
            React.createElement("span", null,
                streamIds.length,
                " ",
                streamIds.length === 1 ? 'Stream' : 'Streams'),
            React.createElement("span", null,
                formattedMsgRate,
                " Msg/s"))));
};
export { DataUnionBadge, StreamStatsBadge };
